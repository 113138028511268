import {  Divider, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useCurrentUser } from "features/Auth/authApi";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import 'react-toastify/dist/ReactToastify.css';
import { getRepartitionReport, getReportsByDay } from "./LogApi";
import { lastDays, repartitionReporter } from "./LogType";
import BarLastDaysChart from "./chart/BarLastDaysChart";
import DonutRepartitionChart from "./chart/DonutRepartitionChart";


const LogPage = () => {
  const dispatch = useDispatch()
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const [repartitionCat, setRepartitionCat] = useState<repartitionReporter[]>([]);
  const [lastDays, setLastDays] = useState<lastDays[]>([]);

  function handleRepartition(data: repartitionReporter[]) {
    if (data) {
      setRepartitionCat(data)
    }
  }

  function handleRepportByDays(data: lastDays[]) {
    if (data) {
      setLastDays(data)
    }
  }

  function getRepartition() {
    dispatch<any>(getRepartitionReport(currentUser!.token, handleRepartition))
  }

  function getLastDays() {
    dispatch<any>(getReportsByDay(currentUser!.token, handleRepportByDays))
  }

  useEffect(() => {
    getRepartition()
    getLastDays()
  }, []);


  return (
    <Grid container p={2}>
      {/* TITLE */}
      <Grid item mb={2} xs={12}>
        <Typography variant="h4" letterSpacing={5} align="left" my={2} gutterBottom >
          Suivi des rapports
        </Typography>
        <Divider sx={{ backgroundColor: 'blue' }} />
      </Grid>
      {/* BLOC IMAGE */}
      <Grid container alignContent="center" alignItems="center" p={1}>
        <Grid item xs={12} mt={5}>
          <Paper >
            <Grid container p={3} alignContent="center" alignItems="center">
              {/* donut repartition */}
              <Grid item xs={6} alignContent="center" alignItems="center">
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="body1" align="center">{`Répartition des bâtiments de provenance`}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <DonutRepartitionChart repartition={repartitionCat} />
                  </Grid>
                </Grid>
              </Grid>
              {/* nombre de rapport 7 derniers jours*/}
              <Grid item xs={6}>
                <Grid container alignContent="center" alignItems="center">
                  <BarLastDaysChart lastDays={lastDays} />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LogPage;


